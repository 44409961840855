// @include border-radius(10px);

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}


// @include font-size(14px)

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}



// @include tablet { }

@mixin tablet {
  @media (max-width: $tablet-bp) {
    @content;
  }
}

@mixin tablet-small {
  @media (max-width: $tablet-small) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile-bp) {
    @content;
  }
}

@mixin mobile-medium {
  @media (max-width: 550px) {
    @content;
  }
}

@mixin small {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin tiny {
  @media (max-width: 400px) {
    @content;
  }
}


// HELPERS

@mixin hidden {
    display: none;
    visibility: hidden;
}

@mixin clear {
    &:before,
    &:after {
        content: " "; /* 1 */
        display: table; /* 2 */
    }
    &:after {
        clear: both;
    }
    *zoom: 1;
}

@mixin ease {
    -webkit-transition:all 150ms ease-in-out;
    -moz-transition:all 150ms ease-in-out;
    -ms-transition:all 150ms ease-in-out;
    -o-transition:all 150ms ease-in-out;
    transition:all 150ms ease-in-out;
}

@mixin ease-slow {
    -webkit-transition:all 650ms ease-in-out;
    -moz-transition:all 650ms ease-in-out;
    -ms-transition:all 650ms ease-in-out;
    -o-transition:all 650ms ease-in-out;
    transition:all 650ms ease-in-out;
}


@mixin no-ease {
    -webkit-transition:none;
    -moz-transition:none;
    -ms-transition:none;
    -o-transition:none;
    transition:none;
}

@mixin border-box {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@mixin light {
    font-weight: 300;
}

@mixin normal {
    font-weight: 400;
}

@mixin medium {
    font-weight: 500;
}

@mixin bold {
    font-weight: 700;
}

@mixin webfont {
    font-family: 'open sans', sans-serif;
}

@mixin font-awesome {
    font-family: "FontAwesome";
    font-style: normal;
}

@mixin black-tie {
    font-family: "BlackTie";
    font-style: normal;
}


@mixin filson-light {
    font-family: "filson-soft",sans-serif;
    font-style: normal;
    font-weight: 200;
    //letter-spacing: 1px;
}

@mixin filson-book {
    font-family: "filson-soft",sans-serif;
    font-style: normal;
    font-weight: 300;
    //letter-spacing: 1px;
}

@mixin filson-medium {
    font-family: "filson-soft",sans-serif;
    font-style: normal;
    font-weight: 500;
    //letter-spacing: 1px;
}

@mixin filson-bold {
    font-family: "filson-soft",sans-serif;
    font-style: normal;
    font-weight: 700;
    //letter-spacing: 1px;
}

@mixin orator {
    font-family: "orator-std",monospace;
    font-weight: normal;
    font-style: normal;
    text-transform: uppercase;
    word-spacing: -2px;
    letter-spacing: 1px;
}

* {
    @include border-box;
}