$documentWidth: 1235px;
$tablet-bp: 1024px;
$tablet-small: 910px; 
$mobile-bp: 780px;

$white: #ffffff;
$yellow: #fde56b;
$blue: #568aba;
$green: #bcf3c9;
$light-blue: #c9e4da;
$peach: #f7b7a4;
$black: #333;
$lightest-grey: #f4f4f4;
$light-grey: #e3e3e3;
$medium-grey: #bdbdbd;
$dark-grey: #999;
$mint: #bcf3c9;


@font-face {
    font-family: 'BlackTie';
    src: url('../../../../public/fonts/BlackTie-Light-webfont.eot?v=4.6.3');
    src: url('../../../../public/fonts/BlackTie-Light-webfont.eot?#iefix&v=4.6.3') format('embedded-opentype'),
        url('../../../../public/fonts/BlackTie-Light-webfont.woff2?v=4.6.3') format('woff2'),
        url('../../../../public/fonts/BlackTie-Light-webfont.woff?v=4.6.3') format('woff'),
        url('../../../../public/fonts/BlackTie-Light-webfont.ttf?v=4.6.3') format('truetype'),
        url('../../../../public/fonts/BlackTie-Light-webfont.svg?v=4.6.3#blacktielight') format('svg');
    font-weight: normal;
    font-style: normal
}