///////////////////////////////////
//// GRIDS
//////////////////////////////////

.pageWrapper {
    width: 100%;
    overflow: hidden;
}

.row {
    margin: 0 -20px 40px -20px;
    @include clear;
    .column {
        float: left;
        padding: 0 20px;
        position: relative;
        width: 100%;
        &.oneQuarter {
            width: 25%;
        }
        &.oneThird {
            width: 33.33%;
        }
        &.oneHalf {
            width: 50%;
        }
        &.twoThirds {
            width: 66.66%;
        }
        &.threeQuarters {
            width: 75%;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
    &.no-stack-small {
        .column {
            &.oneQuarter {
                width: 25%;
            }
            &.oneThird {
                width: 33.33%;
            }
            &.oneHalf {
                width: 50%;
            }
            &.twoThirds {
                width: 66.66%;
            }
            &.threeQuarters {
                width: 75%;
            }
        }
    }
    &.offset {
        position: relative;
        margin-bottom: 0;
        > .column {
            &.twoThirds {
                float: none;
                width: 100%;
                padding-right: 420px;
            }
            &.oneThird {
                width: 400px;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        @include tablet-small {
            > .column {
                &.twoThirds {
                    padding-right: 10px;
                }
                &.oneThird {
                    width: 100%;
                    position: relative;
                    top: auto;
                    right: auto;
                }
            }
        }
    }
    @include tablet {
        margin: 0 -10px 20px -10px;
        .column {
            float: left;
            padding: 0 10px;
            position: relative;
            &.oneQuarter {
                width: 25%;
            }
            &.oneThird {
                width: 33.33%;
            }
            &.oneHalf {
                width: 50%;
            }
            &.twoThirds {
                width: 66.66%;
            }
            &.threeQuarters {
                width: 75%;
            }
        }
    }
    
    @include mobile {
        margin: 0 -10px 0 -10px;
        .column {
            float: left;
            padding: 0 10px 20px 10px;
            position: relative;
            &.oneQuarter {
                width: 100%;
            }
            &.oneThird {
                width: 100%;
            }
            &.oneHalf {
                width: 100%;
            }
            &.twoThirds {
                width: 100%;
            }
            &.threeQuarters {
                width: 100%;
            }
        }
    }
    &.no-padding {
        padding: 0 20px;
        .column {
            padding: 0 0 0;
        }
    }
}

.wrapper {
    max-width: $documentWidth;
    padding: 0 20px;
    margin: auto;
    position: relative;
    img {
        max-width: 100%;
        height: auto;
    }
}
