@import "includes/variables";
@import "includes/mixins";
@import "includes/reset";
@import "includes/grid";

a, input, select, textarea {
  @include ease;
  outline: none;
  &:focus {
    outline: none;
  }
}

.pageWrapper {
  padding: 0;
  background: $white;
}

.sponsors {
  padding: 40px;
  background: $light-grey;
  text-align: center;
  &.leaderboard {
    padding: 20px;
    > div {
      width: 728px;
      height: 90px;
      line-height: 90px;
      @include mobile {
        width: 300px;
        height: 140px;
        line-height: 140px;
      }
    }
  }
  &.mrec {
    padding: 30px;
    &.horizontal {
      margin-top: 20px;
      display: inline-block;
      padding: 20px;
      > div {
        margin: 20px;
      }
    }
  }
}

.medium-banners,
.large-banners {
  a {
    display: block;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.header {
  h1 {
    margin: 0 0;
    padding: 40px 0;
    text-align: center;
    a {
      margin: 0;
      display: inline-block;
      width: 350px;
      height: 52px;
      text-indent: -44444px;
      display: block;
      background: url('https://s3-ap-southeast-2.amazonaws.com/markets.thefinderskeepers.com/website-images/TFK-Logo-Long-Black.png') no-repeat center;
      background-size: contain;
      font-size: 16px;
      @include tablet {
        //margin: 0 auto;
        width: 300px;
      }
      @include small {
        width: 225px;
        overflow: visible;
      }
    }
    @include small {
      padding: 20px 0;
    }
  }
}

.social-media {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 58px;
  right: 10px;
  li {
    padding: 0 !important;
    margin: 0 !important;
    float: left;
    list-style: none;
    &:before {
      display: none;
    }
    a {
      color: $black;
      font-size: 24px;
      display: inline-block;
      padding: 0;
      width: 40px;
      line-height: 40px;
      height: 40px;
      text-align: center;
      &:hover {
        color: $dark-grey;
      }
    }
  }
  .fa-search {
    color: $black;
    font-size: 24px;
    display: inline-block;
    padding: 0;
    width: 40px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    @include ease;
    //background: $light-blue;
    &:hover {
      color: $dark-grey;
    }
  }
  @include tablet {
    top: 56px;
    li {
      a {
        width: 36px;
      }
    }
  }
  @include small {
    top: 30px;
    li {
      display: none;
      &:last-child {
        display: block;
      }
    }
  }
}

.search {
  position: relative;
  background: $lightest-grey;
  overflow: hidden;
  display: none;
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1);
  border-top: 1px solid $lightest-grey;
  input[type="text"] {
    background: none !important;
    padding: 0;
    height: 60px;
    line-height: 60px;
    @include filson-light;
    font-size: 20px;
    border: none;
    width: 100%;
    color: $black;
    &:-webkit-autofill {
      background: transparent !important;
    }
  }
  .fa-search {
    position: absolute;
    top: 10px;
    right: 10px;
    color: $black;
    font-size: 24px;
    display: inline-block;
    padding: 0;
    width: 40px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    @include ease;
    //background: $light-blue;
    &:hover {
      color: $dark-grey;
    }
  }
}

.search-open {
  .social-media {
    .fa-search {
      &:before {
        color: $light-grey;
        //content: "\f00d";
      }
    }
  }
}

.site-switch {
  position: absolute;
  top: 58px;
  right: 230px;
  padding: 0;
  margin: 0;

  li {
    padding: 0;
    margin: 0;
    float: left;
    list-style: none;
    a {
      font-size: 12px;
      display: block;
      @include filson-medium;
      color: #000;
      text-decoration: none;
      text-transform: uppercase;
      padding: 0 17px;
      background: $medium-grey;
      height: 40px;
      line-height: 40px;
      color: $white;
      &:hover {
        background: $black;
      }
      @include tablet {
        padding: 0 15px;
        font-size: 11px;
      }
    }
    &.active {
      a {
        background: $yellow;
        color: $black;
        cursor: default;
      }
    }
  }
  @include tablet {
    right: 200px;
    top: 56px;
  }
  @include mobile {
    right: 60px;
    li {

      display: none;
      &:not(.active) {
        display: block;
      }
    }
  }
  .search {
    display: none;
  }
}

.mainMenu {
  border-top: 1px solid $lightest-grey;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 11888;
  ul {
    padding: 0;
    margin: 0;
    @include clear;
    @include ease-slow;
    //max-height: none;
    //overflow: hidden;
    > li {
      padding: 0;
      margin: 0;
      float: left;
      position: relative;
      &:first-child {
        //margin-left: -15px;
      }
      a {
        display: block;
        @include filson-medium;
        color: #000;
        text-decoration: none;
        text-transform: uppercase;
        padding: 15px 16px;
        @include tablet {
          padding: 15px 13px;
        }
      }

      ul {
        position: absolute;
        top: 49px;
        left: 0;
        //width: 200px;
        width: 200px;
        max-height: 0px;
        background: $yellow;
        @include ease;
        opacity: 0;
        overflow: hidden;

        > li {
          display: block;
          float: none;
          &:first-child {
            margin-left: 0;
          }
          a {
            @include filson-book;
            font-size: 14px;
            display: block;
            padding: 12px 16px;
            color: $black;
            border-top: 1px solid rgba(255, 255, 255, 0.3);
            &:hover {
              box-shadow: none;
              color: $black;
            }
          }

        }
      }
      &.has-submenu {
        span.arrows {
          z-index: 100;
          &:before {
            @include font-awesome;
            content: "\f107";
            display: inline-block;
            font-size: 16px;
            height: 49px;
            line-height: 49px;
            width: 20px;
            text-align: center;
            background: rgba(255, 255, 255, 0.5);
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        > a {
          position: relative;
          padding-right: 20px;

          ul {
            li {
              a {
                &:hover {
                  color: $black;
                }
              }
            }
          }
        }
        &:hover {
          ul {
            opacity: 1;
            max-height: none;
          }
        }
      }
      &:hover {
        > a {
          //background: $white;
          color: $blue;
          @include filson-medium;
          ul {
            //background: $medium-grey;
          }
        }
      }
      &.active {
        > a {
          background: $yellow;
          color: $black;
          @include filson-medium;
        }
        ul {
          li.active {
            > a {
              color: $black;
              background: $white;
              @include filson-medium;
            }
          }
        }
      }
    }
  }
  .menu-button,
  .close-button {
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    @include ease;
    @include filson-medium;
    text-transform: uppercase;
    display: none;
    .fa {
      font-size: 22px;
      line-height: 24px;
      position: relative;
      top: 2px;
      margin-right: 5px;
    }
  }
  @include tablet-small {
    .menu-button,
    .close-button {
      display: block;
    }
    .close-button {
      padding: 0 15px;
      color: $white;
      background: $medium-grey;
      .fa {
        float: right;
        margin-right: 0;
        position: relative;
        top: 12px;
      }
      &:hover {
        background: $peach;
      }
    }
    position: relative;
    overflow: visible;
    ul {
      height: 100%;
      background: $light-grey;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      position: fixed;
      z-index: 11000;
      top: 0;
      left: -260px;
      width: 250px;
      //display: none;
      @include ease;

      li {
        border-bottom: 1px solid $lightest-grey;
        display: block;
        float: none;
        &:first-child {
          margin-left: 0;
        }
        a {
          display: block;
        }
        &.has-submenu {

          span.arrows {
            float: right;
            &:before {
              width: 40px;
            }
          }

          ul {
            position: relative;
            left: auto;
            top: auto;
          }
          &:hover {
            ul {
              display: none;
            }
          }
          &.open {
            ul {
              display: block !important;
              position: relative;
              max-height: none;
              opacity: 1;
              width: 100%;
              box-shadow: none;
              li {
                a {
                  background: $white;
                  box-shadow: none;
                  border-top: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

body {
  @include ease;

}

.menu-open {
  @include tablet-small {
    //padding-left: 250px;

    .mainMenu {
      .wrapper:after {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.5);
        display: block;
        content: "";
        position: fixed;
        z-index: 20000;
      }
      ul {
        z-index: 21000;
        display: block;
        left: 0;
      }
    }
  }
}

.scrolled {
  padding-top: 170px;
  .header {
    position: fixed;
    top: 0;
    left: 0px;
    right: 0px;
    z-index: 10000;
    background: $white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    > .wrapper {
      display: none;
    }
    .mainMenu {
      //max-height: 0px;
      &:before {
        width: 100px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -100px;
        display: block;
        content: "";
        background: $white;
      }
      &:after {
        width: 100px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -100px;
        display: block;
        content: "";
        background: $white;
      }
    }
  }
}

.breadcrumbs {
  //background: $lightest-grey;
  border-top: 1px solid $lightest-grey;
  display: block;
  ul {
    margin: 0;
    padding: 0;
    @include clear;
    li {
      margin: 0;
      padding: 0;
      float: left;
      list-style: none;
      a {
        text-decoration: none;
        @include orator;
        font-size: 16px;
        padding: 20px 15px;
        display: block;
        color: $black;
        @include ease;
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.button {
  @include filson-medium;
  font-size: 20px;
  display: inline-block;
  padding: 14px 30px;
  color: $white;
  background: $black;
  text-decoration: none;
  //text-transform: lowercase;
  &.outline-black {
    border: 1px solid $black;
    background: transparent;
    color: $black;
  }
  &:hover,
  &:focus,
  &:active {
    background: $black;
    color: $white;
  }
}

///////////////////////////////////
//// CAROUSEL
//////////////////////////////////

.carousel {
  position: relative;
  background: #333;
  color: #fff;

  .slide {
    overflow: hidden;
    display: block;
    width: 100%;
    padding: 80px 0 80px 0;
    display: none;
    height: 700px;
    text-align: center;
    @include tablet {
      height: 400px;
    }
    h2 {
      @include filson-medium;
      font-size: 50px;
      margin: 0 auto 30px;
      padding-top: 20px;
      max-width: 600px;
      line-height: 1;
      text-transform: lowercase;
    }
    .backgroundImage {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: cover !important;
      img {
        display: none;
      }
    }
  }
  .cycle-pager {
    text-align: center;
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    z-index: 200;
    span {
      color: #aaa;
      cursor: pointer;
      display: inline-block;
      font-size: 40px;
      line-height: 1;
      padding: 2px 4px;
      @include ease;
      &.hover {
        color: #ccc;
      }
      &.cycle-pager-active {
        color: #fff;
      }
      &#pauseToggle {
        font-size: 12px;
        text-transform: uppercase;
        margin-top: 14px;
        position: absolute;
      }
    }
  }
  .next,
  .previous {
    display: inline-block;
    width: 40px;
    height: 100px;
    text-decoration: none;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    z-index: 200;
    img {
      width: 100%;
      height: 100%;
    }
    display: none;
  }
  .previous {
    left: 0;
  }
  .next {
    right: 0;
  }
}

.page-image {
  padding: 60px 0 0;
  background-size: cover !important;
  background-position: center;
  text-align: center;
  vertical-align: middle;
  position: relative;
  background: $lightest-grey !important;
  //@extend .wrapper;
  img {
    display: none;
  }

}

.market-info {
  background: $lightest-grey;
  //max-width: 1204px;
  @extend .wrapper;
  padding-bottom: 40px;
  //border: 1px solid $black;
  text-align: left;
  h2 {
    margin: 0 0 40px;
    border-bottom: 1px solid $peach;
    padding: 0 0 20px;
    @include filson-medium;
    font-size: 50px;
    text-transform: lowercase;
    span {
      @include orator;
      font-size: 22px;
      display: inline-block;
      margin-left: 5px;
    }
  }
  h3 {
    @include orator;
    font-size: 16px;
  }
  p {
    line-height: 1.5;
    font-size: 15px;
    @include filson-book;
  }
  .entry-fee {
    strong {
      display: block;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 24px;
      @include filson-medium;
      background: $peach;
      @include border-radius(50%);
      -webkit-border-bottom-right-radius: 50%;
      border-bottom-right-radius: 50%;
      color: $black;
      display: inline-block;
    }
    span {
      display: inline-block;
      max-width: 120px;
      line-height: 20px;
      font-size: 15px;
      @include filson-book;
      line-height: 1.5;
      margin-left: 15px;
      position: relative;
      top: -1px;
    }
  }
  .row:last-child {
    margin-bottom: 0;
  }
}

ul.anchor-menu {
  margin: 0;
  padding: 0;
  li {
    padding: 0;
    margin: 0;
    list-style: none;
    &:before {
      display: none;
    }
    a {
      @include filson-book;
      font-size: 13px;
      color: $blue;
      text-decoration: none;
      padding: 2px 0;
      border-top: 1px solid $white;
      display: block;
      &:hover {
        color: $black;
      }
    }
    &:last-child a {
      border-bottom: 1px solid $white;
    }
  }
}

.upcoming-markets {
  display: inline-block;
  width: auto;
  margin: auto;
  background: $white;
  padding: 46px 0;
  text-align: center;
  position: absolute;
  bottom: -80px;
  left: 50%;
  margin-left: -512px;
  width: 1024px;
  z-index: 999;
  ul {
    margin: 0 auto;
    padding: 0;
    max-width: 800px;
    text-align: left;
    li {
      margin: 0;
      padding: 20px 0;
      border-top: 1px solid $light-grey;

      a {
        text-decoration: none;
        display: block;
        @include orator;
        font-size: 22px;
        line-height: 1.2;
        color: $black;
        h3 {
          display: inline-block;
          @include filson-medium;
          text-transform: lowercase;
          font-size: 55px;
          width: 330px;
          margin: 0;
          padding: 0;
        }

        .date {
          display: inline-block;
          width: 80px;
          span {
            display: inline-block;
          }
        }
        .at {
          display: inline-block;
          @include filson-light;
          font-size: 30px;
          position: relative;
          top: -12px;
          padding: 0 10px;
        }
        .location {
          display: inline-block;
          width: 290px;
        }
      }
      &:first-child {
        border-top: none;
        a {
          &:hover {
            color: $blue;
          }
        }
      }
      &:nth-child(2) {
        a {
          &:hover {
            color: $peach;
          }
        }
      }
      &:nth-child(3) {
        a {
          &:hover {
            color: $yellow;
          }
        }
      }
      &:nth-child(4) {
        a {
          &:hover {
            color: $blue;
          }
        }
      }
    }
  }
  @include tablet {
    width: 100%;
    left: auto;
    margin-left: 0;
    bottom: auto;
    position: relative;
  }
  @include tablet-small {
    padding: 10px 20px 20px;
    position: relative;
    ul {
      text-align: center;
      li a h3 {
        display: block;
        width: 100%;
        padding-bottom: 10px;
        font-size: 50px;
      }
      li {
        a {
          .date,
          .at,
          .location {
            //text-align: left;
            width: 100%;
            font-size: 20px;
          }
          .date {
            padding-bottom: 10px;
            display: block;
            width: auto;
          }
          .location {
            display: block;
          }
          .at {
            display: none;
          }
        }
      }
    }
  }
}

.content {
  background: $lightest-grey;
  @include clear;
  padding-bottom: 40px;
  &.white-bg {
    background: $white;
  }
  h2 {
    @include filson-medium;
    font-size: 36px;
    line-height: 1.2;
    border-bottom: 1px solid $black;
    padding-bottom: 10px;
    margin-bottom: 40px;
    .button {
      float: right;
      padding: 10px 15px;
      font-size: 18px;
      background: $black;
      @include filson-book;
      position: relative;
      top: 12px;
    }
    &.red {
      border-color: $peach;
      .button {
        background: $peach;
        color: $black;
        &:hover {
          background: $black;
          color: $white;
        }
      }
    }
  }
  h3 {
    @include filson-medium;
    font-size: 24px;
    line-height: 1.2;
    border-bottom: 1px solid $peach;
    padding-bottom: 10px;
    margin-bottom: 30px;
    margin-top: 25px;
    &:first-child {
      margin-top: 0;
    }
  }
  a {
    color: $blue;
    text-decoration: none;
    @include filson-medium;
    &:hover {
      color: $black;
    }
  }
  p {
    @include filson-light;
    font-size: 15px;
    line-height: 1.6;
    strong,
    b {
      @include filson-medium;
    }
  }
  li {
    @include filson-light;
    font-size: 15px;
    line-height: 1.5;
    strong {
      @include filson-medium;
    }
  }
  ul {
    padding: 0;
    margin: 0 0 15px 5px;
    li {
      padding: 0 0 0 15px;
      margin: 0 0 8px;
      list-style: none;
      position: relative;
      &:before {
        @include font-awesome;
        content: "\f105";
        position: absolute;
        left: 0;
        top: -2px;
        font-size: 16px;
        //color: $peach;
      }
    }
  }
}

.carousel + .content {
  padding-top: 25px;
  @include tablet-small {
    padding-top: 25px;
  }
}

.page-image + .content {
  padding-top: 60px;
}

.content {
  padding-top: 60px;
  &.designer-page {
    padding: 0 0 60px;
    overflow: hidden;
    .row {
      margin-bottom: 0;
      @include mobile {
        .column.oneQuarter {
          display: none;
        }
      }
    }
    h2 {
      margin-bottom: 0;
      padding-bottom: 20px;
      font-size: 50px;
      a.category {
        float: right;
        @include filson-medium;
        font-size: 13px;
        text-transform: uppercase;
        margin-top: 20px;
        color: $black;
        text-decoration: none;
        &:before {
          @include black-tie;
          content: "\F0AA";
          display: inline-block;
          margin-right: 6px;
          font-size: 25px;
          position: relative;
          top: 8px;
        }
        &:hover {
          color: $black;
        }
      }
    }
    p {
      line-height: 1.7;
      @include filson-light;
      font-size: 16px;
      margin: 0 0 20px;
    }
  }
  .designer-header {
    position: relative;
    background: $lightest-grey;
    padding-top: 60px;
    @include mobile {
      padding-top: 25px;
    }
    &:before {
      width: 3000px;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      top: 0;
      left: -3000px;
      background: $lightest-grey;
      display: block;
      content: "";
    }
    &:after {
      width: 3000px;
      height: 100%;
      top: 0;
      right: 0;
      position: absolute;
      top: 0;
      right: -3000px;
      background: $lightest-grey;
      display: block;
      content: "";
    }
  }
  .profile-image {
    width: 100%;
    height: auto;
    position: relative;
    display: block;
    min-height: 20px;
    z-index: 1000;

    img {
      position: absolute;
      top: 50px;
      left: 0;
      @include border-radius(50%);
      //-webkit-border-bottom-right-radius: 50%;
      //-moz-border-radius-bottomright: 50%;
      //border-bottom-right-radius: 50%;
      overflow: hidden;
      border: 20px solid $white;
      @include mobile {

      }
    }
  }
  .designer-links {
    padding: 20px 0 20px;
    margin: 0 0 40px;
    @include clear;
    li {
      display: inline-block;
      float: left;
      padding: 0;
      margin: 0;
      margin-left: 26px;
      &:before {
        display: none;
      }
      &:first-child {
        margin-left: 0;
      }
      a {
        color: $black;
        text-decoration: none;
        @include orator;
        font-size: 16px;
        line-height: 20px;
        .fa {
          font-size: 20px;
          line-height: 1;
        }
      }
    }
  }
  .carousel {
    margin-top: 40px;
    margin-bottom: 40px;
    background: $white !important;
    .next {
      position: absolute;
      z-index: 10000;
      display: block;
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      margin-top: 0;
      opacity: 1;
      background: rgba(255, 255, 255, 0.95);
      border-left: 30px solid $white;
      overflow: hidden;
      &:hover {
        background: rgba(201, 228, 218, 0.95);
      }
      .fa {
        position: absolute;
        top: 50%;
        height: 100px;
        line-height: 100px;
        width: 100px;
        display: block;
        margin-top: -50px;
        left: -50px;
        padding-left: 34px;
        //background: $white;
        text-align: center;
        font-size: 50px;
        color: $black;
        background: $white;
        @include border-radius(50px);
        &:before {
          content: "\F093";
          @include black-tie;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      opacity: 0 !important;
    }
    .slide {
      padding: 0;
      > div {
        //@include ease;
        //opacity: 1 !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        width: 100%;
        height: 100%;
        background-color: $lightest-grey;
      }

      &.cycle-slide-active {
        > div {
          // opacity: 1 !important;
        }
      }
    }
    width: 150%;
    position: relative;
  }
}

.featured-post {
  position: relative;
  @include ease;
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 10;
    outline: none;
  }
  .column:first-child {
    padding-right: 0;
    margin-right: -60px;
  }
  .column:last-child {
    position: relative;
    right: -60px;
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 100%;
      display: block;
      content: "";
      background: $white;
      @include ease;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 20px;
      right: 0;
      width: auto;
      height: 100%;
      display: block;
      content: "";
      background: $light-blue;
      @include ease;
      opacity: 0;
    }
  }
  .post {
    @include clear;
    img {
      width: 33.33%;
      float: right;
    }
    date {
      display: inline-block;
      margin: auto;
      margin-bottom: 20px;
      small {
        background: $black;
        padding: 0 5px;
        line-height: 26px;
        height: 26px;
        @include orator;
        color: #fff;
        display: block;
        letter-spacing: 4px;
        word-spacing: 0;
        padding-right: 2px;
      }
      strong {
        padding: 0 10px;
        line-height: 44px;
        height: 44px;
        min-width: 50px;
        border: 1px solid $black;
        @include filson-book;
        font-size: 24px;
        letter-spacing: 1px;
        display: block;
      }
    }
    > div {
      background: $white;
      padding: 40px 20px 40px 40px;
      text-align: center;
      position: relative;
      @include ease;
      h3 {
        @include filson-medium;
        font-size: 26px;
        line-height: 1.2;
        border-bottom: 0;
        padding-bottom: 0;
      }
      p {
        @include filson-light;
        line-height: 1.6;
        font-size: 15px;
      }
      .button {
        position: absolute;
        bottom: 40px;
        width: 240px;
        left: 50%;
        margin-left: -120px;
        padding: 10px 0;
      }
    }
    .button {
      @include ease;
      display: inline-block;
      @extend .outline-black;
    }
  }
  .column:first-child > div {
    background: $white;
  }
  &:hover {

    .column .post > div {
      background: $light-blue;
    }
    .column:last-child {
      &:after {
        opacity: 0.6;
      }
      &:before {
        background: $light-blue;
      }
    }
  }
  @media (max-width: 1200px) {
    .post {
      > div {
        h3 {
          margin-top: 10px;
          margin-bottom: 20px;
        }
        date {
          margin-top: -40px;
        }
      }
    }
  }
  @media (max-width: 1090px) {
    .post {
      > div {
        p {
          display: none;
        }
        p:last-child {
          display: block;
        }
        date {
          margin-top: 0px;
        }
      }
    }
  }
  @include mobile {
    margin-bottom: 20px;
    padding: 0 10px !important;
    .column {
      width: 100% !important;
      float: none !important;
      &.oneHalf:last-child {
        text-align: right;
        right: auto;
        &:before,
        &:after {
          display: none;
        }

      }
      &.oneHalf:first-child {
        position: absolute;
        z-index: 100;
        width: 350px !important;
        top: 125px;
      }
    }
    &:hover {
      a {
        background: transparent;
      }
    }
  }
}

.tile-posts {
  .post {
    position: relative;
    @include clear;
    margin-bottom: 40px;
    a {
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 100;
    }
    &:before {
      content: "";
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 80;
      background: $light-blue;
      @include ease;
      opacity: 0;
    }
    img {
      width: 100%;
      height: auto;
    }
    date {
      display: inline-block;
      margin: auto;
      margin-bottom: 20px;
      margin-top: -27px;
      small {
        background: $black;
        padding: 0 5px;
        line-height: 26px;
        height: 26px;
        @include orator;
        color: #fff;
        display: block;
        letter-spacing: 4px;
        word-spacing: 0;
        padding-right: 2px;
      }
      strong {
        padding: 0 10px;
        line-height: 44px;
        height: 44px;
        min-width: 50px;
        border: 1px solid $black;
        @include filson-book;
        font-size: 24px;
        letter-spacing: 1px;
        display: block;
      }
    }
    > div {
      @include ease;
      background: $white;
      padding: 0 40px 10px;
      text-align: center;
      position: relative;
      @include ease;
      z-index: 90;
      h3 {
        @include filson-medium;
        font-size: 16px;
        line-height: 1.4;
        border-bottom: 0;
        padding-bottom: 0;
        margin-top: 0;
      }
    }
    &:hover {
      > div {
        background: $light-blue;
      }
      &:before {
        opacity: 0.6;
      }
    }
  }
}

.white-bg {
  .tile-posts {
    .post {
      > div {
        background: $lightest-grey;
      }
      &:hover {
        > div {
          background: $light-blue;
        }
      }
      &.oneThird {
        > div {

        }
      }
    }
  }
  .featured-post {
    .column:last-child {
      &:before {
        background: $lightest-grey;
      }
    }
    .post {
      > div {
        background: $lightest-grey;
      }
      &:hover {
        > div {
          background: $light-blue;
        }

      }
      &.oneThird {
        > div {

        }
      }
    }
    &:hover {
      .column:last-child {
        &:before {
          background: $light-blue;
        }
      }
    }
  }
}

.designer-listing {
  position: relative;
  @include clear;
  margin-bottom: 40px;
  a {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 100;
  }
  &:before {
    content: "";
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 80;
    background: $peach;
    @include ease;
    opacity: 0;
  }
  img {
    width: 100%;
    height: auto;
  }
  > div {
    @include ease;
    background: $lightest-grey;
    padding: 18px 20px 1px;
    text-align: center;
    position: relative;
    @include ease;
    z-index: 90;
    color: $black;
    h3 {
      @include filson-medium;
      font-size: 15px;
      line-height: 1.4;
      border-bottom: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
  &:hover {
    > div {
      background: $peach;
      color: $black;
    }
    &:before {
      opacity: 0.6;
    }
  }

}

.link-box {
  position: relative;
  @include clear;
  margin-bottom: 0;
  a {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 100;
  }
  &:before {
    content: "";
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 80;
    background: $peach;
    @include ease;
    opacity: 0;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  > div {
    @include ease;
    background: rgba(255, 255, 255, 1);
    padding: 18px 20px 1px;
    text-align: center;
    position: relative;
    @include ease;
    z-index: 90;
    color: $black;
    //position: absolute;
    //bottom: 20px;
    //margin-top: -22.5px;
    //left: 0;
    width: 100%;
    h3 {
      @include filson-medium;
      font-size: 15px;
      line-height: 1.4;
    }
  }
  &:hover {
    > div {
      background: $peach;
      color: $black;
    }
    &:before {
      opacity: 0.6;
    }
  }
}

.white-header {
  position: relative;
  background: $white;
  margin-bottom: 60px;
  margin-top: -60px;
  padding-top: 60px;
  h2 {
    margin-bottom: 0;
    padding-bottom: 20px;
  }
  &:before {
    width: 3000px;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    top: 0;
    left: -3000px;
    background: $white;
    display: block;
    content: "";
  }
  &:after {
    width: 3000px;
    height: 100%;
    top: 0;
    right: 0;
    position: absolute;
    top: 0;
    right: -3000px;
    background: $white;
    display: block;
    content: "";
  }
}

.grey-header {
  position: relative;
  background: $lightest-grey;
  margin-bottom: 60px;
  margin-top: -60px;
  padding-top: 60px;
  h2 {
    margin-bottom: 0;
    padding-bottom: 20px;
  }
  &:before {
    width: 3000px;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    top: 0;
    left: -3000px;
    background: $lightest-grey;
    display: block;
    content: "";
  }
  &:after {
    width: 3000px;
    height: 100%;
    top: 0;
    right: 0;
    position: absolute;
    top: 0;
    right: -3000px;
    background: $lightest-grey;
    display: block;
    content: "";
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid $lightest-grey;
  background: $lightest-grey url(/images/select-arrow.png) no-repeat center right;
  background-size: 25px auto;
  padding: 10px 40px 10px 10px;
  font-size: 14px;
  @include orator;
  color: $black;
  @include ease;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: $light-blue;
    border-color: $light-blue;
  }
}

.tag {
  position: absolute;
  top: 0;
  @include border-box;
  left: 50%;
  text-align: center;
  display: inline-block;
  padding: 10px 0;
  color: $black;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 1;
  @include orator;
  content: "";
  z-index: 1000;
  text-decoration: none !important;
}

.Food {
  position: relative;
  a:before {
    width: 70px;
    margin-left: -35px;
    @extend .tag;
    content: "Food" !important;
    background: $yellow;
  }
}

.Debut {
  position: relative;
  a:before {
    width: 80px;
    margin-left: -40px;
    @extend .tag;
    content: "Debut" !important;
    background: $light-blue;
  }
}

.New {
  position: relative;
  a:before {
    width: 80px;
    margin-left: -40px;
    @extend .tag;
    content: "New" !important;
    background: $peach;
  }
}

.instagram-container {
  padding-top: 60px;
  padding-bottom: 60px;
  h2 {
    @include filson-medium;
    font-size: 36px;
    line-height: 1.2;
    border-bottom: 1px solid $light-blue;
    padding-bottom: 10px;
    margin-bottom: 25px;
    text-transform: lowercase;
    .button {
      float: right;
      padding: 10px 15px;
      font-size: 18px;
      background: $black;
      @include filson-book;
    }
  }
  .social-media {
    top: 1px;
    li {
      a {
        &:hover {
          //background: $light-blue;
        }
      }
      
    }
    @include small {
      right: auto;
      left: 8px;
      top: -38px;
      display: block;
      float: none;
      li {
        display: block;
      }
    }
  }
  .lightwidget--slideshow.lightwidget--image-square .lightwidget__link {
    box-shadow: 10px 10px 0 $light-grey;
    overflow: visible;
  }
  .instagram-feed {
    margin: 0 -20px;
  }
  .hashtag {
    @include orator;
    font-size: 16px;
    text-align: center;
    display: block;
    margin: 20px auto 0;
    text-decoration: none;
    color: $black;
  }
}

.newsletter {
  background: $yellow;
  form {
    padding: 20px 0;
    background: none;
    margin: auto;
    max-width: 1024px;
    fieldset {
      background: transparent;
      margin: auto;
      padding: 0 0 0 280px;
      @include border-radius(0px);
      max-width: 100%;
      position: relative;
      p {
        padding: 0;
        margin: 0;
        label {
          position: absolute;
          left: 18px;
          top: 4px;
          width: auto;
          font-size: 16px;
          @include orator;
          color: #000;
          width: 240px;
          padding-left: 0;
          display: inline-block;
          text-align: left;
          line-height: 1.2;
          //white-space: nowrap;
          @include small {
            font-size: 18px;
            padding-right: 0;
          }
          @include tiny {
            @include webfont;
            font-size: 15px;

          }
        }
        input,
        textarea,
        select {
          border: 1px solid $black;
          width: 100%;
          float: none;
          background: rgba(255, 255, 255, 0);
          color: $black;
          padding: 10px 140px 10px 15px;
          @include filson-book;
          font-size: 16px;
          &:hover,
          &:focus {
            //background: $white;
            //background: rgba(255,255,255,0.3);
          }
        }

        ::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }

        :-moz-placeholder { /* Firefox 18- */
          color: rgba(0, 0, 0, 0.5);
        }

        ::-moz-placeholder { /* Firefox 19+ */
          color: rgba(0, 0, 0, 0.5);
        }

        :-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.5);
        }
        &.form-actions {
          position: absolute;
          right: 0;
          top: 0;
          border: none;
          input {
            padding: 11px 20px;
            width: 100%;
            margin: 0;
            border: none;
            border-left: 1px solid $black;
            @include border-radius(0);
            background: none;
            box-shadow: none;
            min-width: 1px;
            @include filson-medium;
            text-transform: lowercase;
            &:hover {
              background: $black;
              color: $white;
            }
          }
        }
      }
      @include small {
        padding-left: 0;
        p {
          label {
            position: relative;
            width: auto;
            display: block;
            left: auto;
            top: auto;
            margin-top: 0;
            margin-bottom: 10px;
          }
          input {

          }
          &.form-actions {
            top: auto;
            bottom: 0;
          }
        }
      }
    }
  }

}

.sign-up-badge {
  display: inline-block;
  display: none;
  padding: 10px 10px 0;
  width: 80px;
  font-size: 12px;
  background: $yellow;
  text-align: center;
  @include orator;
  line-height: 1.1;
  position: absolute;
  top: 0;
  right: 20px;
  display: block;
  z-index: 10000;
  cursor: pointer;
  @include ease;

  .fa {
    display: block;
    padding-bottom: 0;
    font-size: 20px;
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg);
  }
  &:before {
    content: "";
    position: absolute;
    top: 56px;
    right: 0;
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 20px solid $yellow;
    @include ease;
  }
  &:hover {
    padding-top: 25px;
    &:before {
      top: 71px;
    }
  }
}

.footer-links {
  background: $black;
  color: $white;
  padding: 50px 0;
  @include clear;
  .row {
    margin-bottom: 0;
  }
  .row .column {
    width: 100%;
  }
  .logo {
    margin-right: 65px;
    float: left;
    max-width: 360px;
    display: inline-block;
    img {
      width: 100%;
      max-width: 150px;
    }
  }
  .copyright {
    float: left;
    font-size: 11px;
    max-width: 360px;
    line-height: 1.4;
    padding-top: 20px;
    @include filson-light;
    h2 {
      font-size: 13px;
      @include orator;
    }
    p {
      line-height: 1.6;
      opacity: 0.8;
    }
  }
  .links {
    float: right;
    padding-top: 5px;
    ul {
      width: 356px;
      li {
        width: 50%;
        float: left;
        display: block;
        padding-bottom: 10px;
        &:before {
          display: none;
        }
        a {
          display: block;
          font-size: 15px;
          @include orator;
          text-decoration: none;
          color: $white;
          span {
            display: inline-block;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            @include ease;
          }
          &:hover {
            span {
              border-color: $light-blue;
            }
          }
        }
      }
    }
    @include tablet {
      float: none;
      clear: both;
      ul {
        padding-left: 0;
        margin-left: 215px;
      }
    }
    @include mobile {
      float: none;
      clear: both;
      ul {
        width: 100%;
        padding-left: 0;
        margin-left: 0;
        li {
          width: 100%;
          float: none;
        }
      }
    }
  }
}

.form fieldset {
  margin-bottom: 40px;
  width: 100%;
  #image-uploads {
    input {
      margin-bottom: 10px;
    }
  }
  > div {
    clear: both;
  }
  .oneHalf {
    width: 50%;
    float: left;
    clear: right;
  }
  .oneThird {
    width: 33.33%;
    float: left;
    clear: right;
  }
  h2,
  legend {
    padding: 15px 20px;
    margin: 0;
    background: $light-blue;
    //color: $white;
    border: none;
    @include filson-medium;
    font-size: 20px;
    text-transform: none;
  }
  h2 + label {
    background: $lightest-grey;
    padding: 15px 20px 10px;
    display: block;
    margin: 0;
    @include filson-medium;
    font-size: 16px;
    & + div {
      border-top: none !important;
      padding-top: 0 !important;
    }
  }
  legend + label {
    background: $lightest-grey;
    padding: 15px 20px 0px;
    display: block;
    margin: 0;
    @include filson-medium;
    font-size: 16px;
    & + div {
      border-top: none !important;
      padding-top: 0 !important;
    }
  }
  &:first-child legend:first-child {
    padding-top: 15px;
  }
  > div,
  > .form-group {
    padding: 20px;
    margin: 0;
    background: $lightest-grey;
    border-left: none;
    border-radius: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid $white;
    position: relative;
    @include clear;
    #location-requests,
    #other-requests,
    #show-video {
      padding-top: 15px;
    }
    .text {
      label {
        @include filson-book;
        input {
          margin-bottom: 15px;
        }
      }
    }
    .radio,
    .checkbox {
      margin-top: 0;
      margin-bottom: 0;
      @include clear;
      & + .form-group {
        padding-top: 20px !important;
      }
    }
    div.radio > .radio,
    div.checkbox > .checkbox,
    > .radio,
    > .checkbox {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    > .form-group {
      padding-top: 20px;
    }
    &.addon-container {
      padding: 0 !important;
      margin: 0 !important;
      border: none !important;
      &:last-child {
        padding-bottom: 20px !important;
      }
      .form-group {
        padding: 0 20px 0;
        margin: 0;
        //background: $lightest-grey;
        //border-bottom: 1px solid rgba(0,0,0,0.1);
        //border-top: 1px solid $white;
        position: relative;
        .form-group {
          padding: 0;
          border: none;
        }

      }
      & + label {
        background: $lightest-grey;
        padding: 15px 20px 0;
        display: block;
        margin: 0;
        @include filson-medium;
        font-size: 16px;
      }
    }
    .subs {
      background: $lightest-grey;
      //border-bottom: 1px solid rgba(0,0,0,0.1);
      padding: 0 0 10px 45px;
      .form-group > label {
        font-size: 1px !important;
        color: $lightest-grey;
        margin-bottom: 5px;
      }
    }
    &.required {
      &:before {
        @include font-awesome;
        content: "\f06a";
        position: absolute;
        right: 13px;
        top: 16px;
        font-size: 20px;
        //color: $medium-grey;
        @include ease;
        position: absolute;
        top: 15px;
        right: 20px;
        //color: $peach;
      }
    }
    label,
    h4 {
      @include filson-medium;
      font-size: 16px;
      display: block;
      padding: 0;
      margin: 0;
      margin-bottom: 10px;
    }
    label.quantity {
      display: inline-block;
      //font-weight: 400;
      //font-style: italic;
      padding: 0 15px;
      font-size: 14px;
      line-height: 43px;
      margin-left: 1px;
      background: rgba(255, 255, 255, 1);
      & + input {
        display: inline-block;
        width: 80px;
        float: left;
        margin-right: 0;
      }
    }
    input[type="text"],
    input[type="password"],
    input[type="file"],
    input[type="email"] {
      padding: 10px;
      width: 100%;
      background: $white;
      border: 1px solid $white;
      font-size: 16px;
      @include filson-light;
      &:hover {
        //background: $lightest-grey;
        border-color: $light-grey;
      }
      &:focus {
        background: $white;
        border-color: $light-grey;
      }
    }
    button#show-more-image-uploads,
    button#show-less-image-uploads {
      border: none;
      @extend .button;
      font-size: 14px;
      background: $black;
      color: $white;
      padding: 10px 20px;
      &:hover {
        background: $peach;
        color: $black;
      }
    }
    input[type="submit"] {
      border: none;
      @extend .button;
      font-size: 16px;
      background: $black;
      color: $white;
      &:hover {
        background: $peach;
        color: $black;
      }
    }
    textarea {
      padding: 10px;
      width: 100%;
      height: 100px;
      background: $white;
      border: 1px solid $white;
      font-size: 16px;
      @include filson-light;
      &:hover {
        //background: $lightest-grey;
        border-color: $light-grey;
      }
      &:focus {
        background: $white;
        border-color: $light-grey;
      }
    }
    select {
      padding: 10px;
      font-size: 16px;
      background-color: $white;
      border-color: $white;
      width: 100%;
      @include filson-light;
      text-transform: none;
      &:hover {
        //background-color: $lightest-grey;
        border-color: $light-grey;
      }
      &:focus {
        background-color: $white;
        border-color: $light-grey;
      }
    }
    .radio,
    .checkbox {
      @include clear;
      & + .form-group {
        padding-top: 15px;
      }
    }
    .radio-button,
    .checkbox label,
    .radio label {
      @include clear;
      position: relative;
      &.radio-inline {
        display: inline-block;
        float: left;
        margin-right: 10px;
        margin-bottom: 0;
      }
      input {
        position: absolute;
        top: 14px;
        left: 14px;
      }
      padding: 11px 10px 9px 44px;
      background: $white;
      font-size: 16px;
      @include filson-light;
      @include ease;
      border: 1px solid $white;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        background-color: $lightest-grey;
        border-color: $light-grey;
        cursor: pointer;
      }
      .supporting-text {
        padding-bottom: 0;
        padding-top: 5px;
      }
    }
    .radio {
      & + textarea {
        position: relative;
        top: 15px;
        margin-bottom: 15px;
      }
    }

    .iradio_square,
    .icheckbox_square {
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .supporting-text {
      font-size: 13px;
      @include filson-light;
      //font-style: italic;
      padding-bottom: 15px;
      &.bottom {
        padding-bottom: 0;
        padding-top: 10px;
      }
    }
    &.action-buttons {
      text-align: right;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
    #progress > h2 {
      background: none !important;
      padding: 15px 0 !important;
      color: $blue !important;
    }
  }
  h2 + div,
  legend + div {
    border-top: none !important;
  }
  h2 + div.oneHalf,
  h2 + div.oneThird {
    border-top: 1px solid $white;
  }
  .help-block {
    font-size: 13px;
    color: $black;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .has-error {
    .help-block {
      padding: 10px;
      background: $peach;
      color: $black;
    }
  }
  i.form-control-feedback {
    display: none !important;
  }
}

.form-control {
  height: auto;
}

ul + .form {
  margin-top: 30px;
}

.dashboard {

  .header {
    background: $black;
    h1 {
      padding: 30px 0;
      a {
        background-image: url(/images/TFK-Logo-Long-White.png);
      }
    }
  }
  .mainMenu {
    border-top: none;
    background: $medium-grey;
    li {
      a {
        color: $white;
      }
    }
  }
  .footer {
    position: relative !important;
    bottom: auto !important;
  }
}

body.dark {
  background: $black;
}

ul.ancillary {
  position: absolute;
  bottom: 30px;
  right: 0;
  padding: 0;
  margin: 0;
  li {
    float: left;
    padding: 0;
    margin: 0 20px 0 0;
    list-style: none;
    color: $white;
    @include orator;
    a,
    span {
      color: $white;
      text-decoration: none;
      @include orator;
      .fa {
        margin-right: 10px;
        font-size: 20px;
        line-height: normal;
        position: relative;
        top: 2px;
      }
    }
  }
}

/// BLOG

.blog-post .grey-header {
  h2 {
    border-bottom: none;
    padding-bottom: 30px;
  }
}

.entry {
  h1 {
    @include filson-medium;
    font-size: 44px;
    border-bottom: 1px solid $peach;
    padding-bottom: 10px;
    margin-bottom: 15px;
    margin-top: 40px;
    &:first-child {
      margin-top: 0;
    }
  }
  h2 {
    border: none;
    padding-bottom: 0;
    margin-bottom: 20px;
    margin-top: 40px;
    &:first-child {
      margin-top: 0;
    }
  }
  h3 {
    border: none;
    padding: 15px 20px;
    background: $yellow;
    margin-top: 40px;
    &:first-child {
      margin-top: 0;
    }
  }
}

blockquote {
  @include filson-book;
  font-style: italic;
  font-size: 22px;
  margin: 40px 0;
  padding: 25px 30px;
  background: $light-blue;
}

.blog-post {
  h4 {
    margin-top: 40px;
    font-size: 16px;
    @include filson-book;
    text-transform: uppercase;
    border-bottom: 1px solid $black;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
}

.wp-caption {
  width: auto !important;
  display: inline-block;
  margin-bottom: 40px;
  @include clear;
  img {
    width: 100%;
    margin-bottom: 0;
  }
  p {
    //border-bottom: 1px solid $dark-grey;
    display: block;
    margin: 0;
    padding: 15px;
    @include orator;
    font-size: 13px;
    line-height: 1.5;
    text-transform: uppercase;
    //color: #888;
    position: relative;
    padding-left: 48px;
    //color: $white;
    background: $lightest-grey;
    &:before {
      content: "\f030";
      font-family: FontAwesome;
      margin-right: 5px;
      font-style: normal;
      font-size: 16px;
      line-height: 16px;
      position: absolute;
      top: 15px;
      left: 15px;
    }
    a {
      @include orator;
      color: $black;
      line-height: 1.2;
      text-decoration: none;
      display: inline-block;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      &:hover {
        border-color: $black;
      }
    }
  }
}

p + .wp-caption {
  margin-top: 20px;
}

.author {
  @include filson-book;
  display: inline-block;
  font-size: 13px;
  color: $black;
  margin-top: -8px !important;
  position: relative;
  padding: 15px 30px 14px 90px;
  //background: $light-grey;
  text-transform: uppercase;
  margin-bottom: 32px;
  //color: $medium-grey;
  a {
    color: $black;
  }
  img {
    position: absolute;
    top: -6px;
    left: 0;
    width: 70px;
    height: auto;
    overflow: hidden;
    -webkit-filter: grayscale(1);
    -webkit-filter: grayscale(100%);
    filter: gray;
    filter: grayscale(100%);
    @include border-radius(50%);

  }
}

.metaData {
  display: block;
  padding-top: 25px;
  .categories {
    a {
      display: inline-block;
      background: $light-blue;
      font-size: 13px;
      @include orator;
      text-transform: uppercase;
      color: $medium-grey;
      margin-right: 10px;
      margin-bottom: 5px;
    }
    display: none;
  }
  .tags {
    a {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 5px;
      font-size: 13px;
      @include orator;
      text-transform: uppercase;
      color: $medium-grey;
      &:before {
        content: "#";

      }
    }
  }
}

.addthis_sharing_toolbox {
  @include clear;
  margin-bottom: 15px;
  &:before {
    display: inline-block;
    font-size: 20px;
    line-height: 32px;
    @include orator;
    content: "Share";
    padding-right: 10px;
    float: left;
  }
}

ul.commentlist {
  padding: 0;
  margin: -20px 0 40px;
  font-size: 14px;
  p {
    font-size: 14px;
  }
  li {
    padding: 20px 0 5px 46px;
    margin: 0;
    background: none;
    border-top: 1px solid $lightest-grey;
    list-style: none;
    &:before {
      display: none;
    }
    &:first-child {
      border-top: none;
    }
    .vcard {
      display: inline-block;
      img {
        float: left;
        margin-left: -46px;
        @include border-radius(50%);
        overflow: hidden;
      }
      a,
      .fn,
      cite {
        color: $black;
        @include filson-medium;
      }
      .says {
        display: none;
      }
    }
    .comment-meta {
      padding-left: 10px;
      display: inline-block;
      opacity: 0;
      @include ease;
      float: right;
      display: none;
      a {
        @include orator;
        color: $black;
        font-size: 13px;
      }
    }
    &:hover {
      .comment-meta {
        opacity: 0.5;
      }
    }
  }
}

.categories {
  margin-bottom: 40px;
  h3 {
    padding: 15px 15px 14px;
    @include filson-medium;
    font-size: 15px;
    border: none;
    background: $lightest-grey;
    text-transform: uppercase;
    cursor: pointer;
    @include ease;
    margin-bottom: 0;
    border-top: 1px solid $white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &:hover {
      background: $light-grey;
    }
    &:before {
      content: "\f107";
      font-family: FontAwesome;
      margin-right: 5px;
      font-style: normal;
      font-size: 21px;
      float: right;
      position: relative;
      top: -3px;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      &:before {
        display: none;
      }
      a {
        display: block;
        padding: 10px 15px;
        background: $lightest-grey;
        border-top: 1px solid $white;
        border-bottom: 1px solid $light-grey;
        &:hover {
          background: $white;
        }
      }
    }
  }
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.navigation {
  @include clear;
  padding: 0 20px;
  a {
    display: block;
    border-bottom: 1px solid $light-grey;
    color: $black;
    &:hover {
      border-color: $black;
    }
  }
}

.question {
  h3 {
    padding: 12px 55px 11px 15px;
    border: none;
    background: $lightest-grey;
    font-size: 14px;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid $white;
    position: relative;
    cursor: pointer;
    @include ease;
    line-height: 1.6;
    &:before {
      @include font-awesome;
      content: "\f078";
      position: absolute;
      right: 12px;
      top: 10px;
      font-size: 16px;
      //color: $medium-grey;
      @include ease;
    }
    &:hover {
      background: $light-grey;
      &:before {
        color: $black;
      }
    }
  }
  &.open {
    h3 {
      &:before {
        -ms-transform: rotate(-90deg); /* IE 9 */
        -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
        transform: rotate(-90deg);
      }
    }
  }
  .answer {
    background: $lightest-grey;
    padding: 15px 15px 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid $white;
    display: none;
  }
}

.question-block {
  padding: 10px 0 30px;
  &.smaller {
    max-width: 600px;
    h3 {
      padding: 10px 55px 9px 10px;
      font-size: 14px;
      &:before {
        font-size: 16px;
        top: 10px;
        right: 10px;
      }
    }
  }
}

.status-block {
  margin-bottom: 40px;
}

.application-status {
  background: $lightest-grey;
  padding: 20px 120px 20px 20px;
  position: relative;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  h4 {
    padding: 0;
    margin: 4px 0;
    @include filson-medium;
    text-transform: none;
    border-bottom: none;
    font-size: 14px;
    strong {
      float: none;
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 16px;
      font-size: 18px;
      display: block;
      @include filson-medium;
      background: $medium-grey;
      color: $lightest-grey;
      text-transform: lowercase;
      text-align: center;
      line-height: 1;
      //width: 140px;
    }
  }
  &.in-review {
    background: $yellow;
    h4 {
      strong {
        background: rgba(255, 255, 255, 0.6);
        color: $black;
      }
    }
  }
  &.results-sent {
    background: $light-blue;
    h4 {
      strong {
        background: rgba(255, 255, 255, 0.6);
        color: $black;
      }
    }
  }
}

.logout {
  margin-bottom: 40px;
  .button {
    color: $white;
    background: $medium-grey;
    padding: 10px 16px;
    font-size: 18px;
    text-transform: lowercase;
    &:hover {
      background: $black;
      color: $white;
    }
  }
}

.application-block {
  background: $lightest-grey;
  padding: 20px 170px 20px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  h4 {
    padding: 0;
    margin: 3px 0 2px;
    @include filson-medium;
    text-transform: none;
    border-bottom: none;
    font-size: 14px;
  }
  .button {
    background: none;
    color: $black;
    float: none;
    position: absolute;
    top: 18px;
    right: 20px;
    padding: 15px 0;
    font-size: 18px;
    display: none;
    text-transform: lowercase;
    border: 1px solid $black;
    background: $black;
    color: $white;
    width: 130px;
    text-align: center;
    line-height: 1;
    &:hover {
      background: #555;
      color: $white;
      border-color: #555;
    }
  }

  &.open {
    .button {
      display: block;
    }
  }
  &.open {
    background: $light-blue;
  }
  &.closed {
    color: $medium-grey;
    &:after {
      float: none;
      position: absolute;
      top: 18px;
      right: 20px;
      padding: 16px 0;
      font-size: 18px;
      display: block;
      content: "Closed";
      @include filson-medium;
      background: $medium-grey;
      color: $lightest-grey;
      text-transform: lowercase;
      width: 130px;
      text-align: center;
      line-height: 1;
    }
  }
  &.impending {
    color: $black;
    &:after {
      float: none;
      position: absolute;
      top: 18px;
      right: 20px;
      padding: 16px 0;
      font-size: 18px;
      display: block;
      content: "Upcoming";
      @include filson-medium;
      background: rgba(255, 255, 255, 1);
      color: $black;
      text-transform: lowercase;
      width: 130px;
      text-align: center;
      line-height: 1;
    }

  }
  p {
    margin-bottom: 0;
  }
  @include mobile {
    padding-right: 20px;
    .button,
    &.closed:after,
    &.impending:after {
      position: relative;
      margin-left: 0;
      margin-top: 20px;
      width: 100%;
      top: auto;
      right: auto;
    }
  }
}

.leave-a-comment {
  h2 {
    background: $lightest-grey !important;
    @include ease;
    &:before {
      @include font-awesome;
      content: "\f086";
      display: inline-block;
      padding-left: 5px;
      font-size: 26px;
      line-height: 1;
      float: right;
      margin-top: -2px;
    }
    cursor: pointer;
    &:hover,
    &.open {
      background: $light-grey !important;
    }
  }
  fieldset > div {
    display: none;
  }
}

.alert {
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 15px 60px 15px 20px;
  margin-bottom: 30px;
  color: rgba(0, 0, 0, 0.8);
  @include clear;
  @include filson-book;
  @include border-radius(0);
  font-size: 15px;
  position: relative;

  strong {
    @include filson-medium;
  }
  &.alert-success {
    background: $green;
  }
  &.alert-info {
    background: $blue;
    color: $white;
    a {
      color: $white;
      border-color: $white;
    }
  }
  &.alert-warning {
    background: $yellow;
  }
  &.alert-danger {
    background: $peach;
  }
  .close {
    float: right;
    margin-left: 20px;
    background: none;
    color: $black;
    border: none;
    font-size: 30px;
    @include filson-light;
    padding: 0;
    position: absolute;
    top: 5px;
    height: 10px;
    right: 15px;
    &:focus {
      outline: none;
    }
  }
  a {
    color: rgba(0, 0, 0, 0.8);
    display: inline-block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    line-height: 1.4;
    @include filson-medium;
    &:hover {
      border-color: $black;
    }
  }
}

.form-group {
  .alert {
    margin-bottom: 15px;
    clear: both;
    position: relative;
    top: 15px;
  }

}

.k-block, .k-widget {
  background: rgba(255, 255, 255, 0.7);
}

.k-filter-row th, .k-grid-header th.k-header, .k-grid-header, .k-grouping-header {
  background: $light-grey;
  border-color: $lightest-grey;
  .k-group-indicator {
    background: $lightest-grey;
  }
}

.k-autocomplete, .k-block, .k-button-group .k-tool, .k-calendar th, .k-content, .k-dropdown-wrap, .k-dropzone-active, .k-editable-area, .k-filter-row > th, .k-footer-template td, .k-grid td, .k-grid td.k-state-selected, .k-grid-content-locked, .k-grid-footer, .k-grid-footer-locked, .k-grid-footer-wrap, .k-grid-header, .k-grid-header-locked, .k-grid-header-wrap, .k-group, .k-group-footer td, .k-grouping-header, .k-grouping-header .k-group-indicator, .k-header, .k-input, .k-pager-refresh, .k-pager-wrap, .k-pager-wrap .k-link, .k-panel > .k-item > .k-link, .k-panelbar .k-content, .k-panelbar .k-panel, .k-panelbar > .k-item > .k-link, .k-popup.k-align .k-list .k-item:last-child, .k-separator, .k-slider-track, .k-splitbar, .k-state-default, .k-state-default .k-select, .k-state-disabled, .k-textbox, .k-textbox > input, .k-tiles, .k-toolbar, .k-tooltip, .k-treemap-tile, .k-upload-files, .k-widget {
  border-color: $light-grey;
}

.k-button, .k-widget {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.dropZoneElement {
  background: $medium-grey;
  border: 1px solid $medium-grey;
  .dropImageHereText {
    line-height: 1.5;
    font-size: 12px;
    padding-top: 15px;
    color: $white;
    .fa {
      margin-bottom: 15px;
    }
  }
}

.k-dropzone-hovered {
  background: $medium-grey;
}

ul.k-upload-files {
  padding: 20px;
}

li.k-file {
  padding: .65em .17em .65em 1em;
}

.k-file-name {
  padding: 5px;
}

.mainMenu {
  ul {
    margin-left: -15px;
    @include tablet-small {
      margin-left: 0;
    }
  }
  .login {
    position: absolute;
    right: 0;
    top: 1px;
    a {
      display: block;
      font-family: "filson-soft", sans-serif;
      font-style: normal;
      font-weight: 500;
      color: #000;
      text-decoration: none;
      text-transform: uppercase;
      padding: 15px 16px;
      .fa {
        font-size: 20px;
        margin-right: 8px;
        margin-top: -1px;
        float: left;
      }
    }
  }
}

.designer-listings {
  //display: none;
    .column {
        box-sizing: border-box;
    }
}

.designer-listing {
    position: relative;
    @include clear;
    padding-bottom: 46px;
    margin-bottom: 30px;
    a {
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: 100;
    }
    &:before {
        content: "";
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: 80;
        background: $peach;
        @include ease;
        opacity: 0;
    }
    img {
        width: 100%;
        height: auto;
    }
    .square-crop {
        display: block;
        background: $lightest-grey no-repeat center;
        background-size: cover;
        position: relative;
        max-height: 150px;
        overflow: hidden;
        img {
            //display: none;
        }
    }
    > div {
        @include ease;
        background: $lightest-grey;
        padding: 15px 15px;
        text-align: center;
        position: relative;
        @include ease;
        z-index: 90;
        color: $black;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        h3 {
            @include filson-medium;
            font-size: 14px;
            line-height: 1.4;
            border-bottom: 0;
            padding-bottom: 0;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    &:hover {
        >div {
            background: $peach;
            color: $black;
        }
        &:before {
            opacity: 0.6;
        }
    }
}


.tag {
    position: absolute;
    top: 0;
    @include border-box;
    left: 50%;
    text-align: center;
    display: inline-block;
    padding: 6px 0;
    color: $black;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1;
    @include orator;
    content: "";
    z-index: 1000; 
    text-decoration: none !important;
}

.food {
    position: relative;
    a:before {
        width: 60px;
        margin-left: -30px;
        @extend .tag;
        content: "Food";
        background: $yellow;
    }
}

.debut {
    position: relative;
    a:before {
        width: 70px;
        margin-left: -35px;
        @extend .tag;
        content: "Debut";
        background: $light-blue;
    }
}

.new {
    position: relative;
    a:before {
        width: 54px;
        margin-left: -27px;
        @extend .tag;
        content: "New";
        background: $mint;
    }
}

.content {
  padding-top: 60px;
  &.designer-page {
    padding: 0 0 60px;
    overflow: hidden;
    .row {
      margin-bottom: 0;
      @include mobile {
        .column.oneQuarter {
          display: none;
        }
      }
    }
    h2 {
      margin-bottom: 0;
      padding-bottom: 20px;
      font-size: 50px;
      font-weight: 300;
            border-color: #e3e3e3 !important;
      .category {
        float: right;
        @include filson-medium;
        font-size: 14px;
        text-transform: uppercase;
        margin-top: 20px;
        color: $peach;
        text-decoration: none;
        &:before {
          @include black-tie;
          content: "\F0AA";
          display: inline-block;
          margin-right: 6px;
          font-size: 25px;
          position: relative;
          top: 8px;
        }
        &:hover {
          color: $black;
        }
      }
      @include mobile {
        font-size: 40px;
        line-height: 1.15;
      }
    }
    p {
      line-height: 1.7;
      @include filson-light;
      font-size: 16px;
      margin: 0 0 20px;
    }
  }
  .designer-header {
    position: relative;
    background: $lightest-grey;
    padding-top: 60px;
    @include mobile {
      padding-top: 25px;
    }
    &:before {
      width: 3000px;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      top: 0;
      left: -3000px;
      background: $lightest-grey;
      display: block;
      content: "";
    }
    &:after {
      width: 3000px;
      height: 100%;
      top: 0;
      right: 0;
      position: absolute;
      top: 0;
      right: -3000px;
      background: $lightest-grey;
      display: block;
      content: "";
    }
  }
  .profile-image {
    width: 100%;
    height: auto;
    position: relative;
    display: block;
    min-height: 20px;
    z-index: 1000;

    img {
      position: absolute;
      top: 50px;
      left: 0;
      @include border-radius(50%);
      //-webkit-border-bottom-right-radius: 50%;
      //-moz-border-radius-bottomright: 50%;
      //border-bottom-right-radius: 50%;
      overflow: hidden;
      border: 20px solid $white;
      @include mobile {

      }
    }
  }
  .designer-links {
    padding: 20px 0 20px;
    margin: 0 0 40px;
    @include clear;
    li {
      display: inline-block;
      float: left;
      padding: 0;
      margin: 0;
      margin-left: 26px;
      &:before {
        display: none;
      }
      &:first-child {
        margin-left: 0;
      }
      a {
        color: $black;
        text-decoration: none;
        @include orator;
        font-size: 16px;
        line-height: 20px;
        .fa {
          font-size: 20px;
          line-height: 1;
        }
      }
      @include small {
        //float: none;
        a {
          font-size:0;
          display: block;
          line-height: 24px;
          height: 24px;
          .fa {
            font-size: 24px;
          }
        }
      }
    }
  }
  .carousel {
    margin-top: 40px;
    margin-bottom: 40px;
    background: $white !important;
    .next {
      position: absolute;
      z-index: 10000;
      display: block;
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      margin-top: 0;
      opacity: 1;
      background: rgba(255,255,255,0.95);
      border-left: 30px solid $white;
      overflow: hidden;
      &:hover {
        background: rgba(201,228,218,0.95);
      }
      .fa {
        position: absolute;
        top: 50%;
        height: 100px;
        line-height: 100px;
        width: 100px;
        display: block;
        margin-top: -50px;
        left: -50px;
        padding-left: 34px;
        //background: $white;
        text-align: center;
        font-size: 50px;
        color: $black;
        background: $white;
        @include border-radius(50px);
        &:before {
          content: "\F093";
          @include black-tie;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      opacity: 0 !important;
    }
    .slide {
      padding: 0;
      >div {
        //@include ease;
        //opacity: 1 !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        width: 100%;
        height: 100%;
        background-color: $lightest-grey;
      }

      &.cycle-slide-active {
        >div {
          // opacity: 1 !important;
        }
      }
    }
    width: 150%;
    position: relative;
    @include mobile {
      width: 180%;
    }
    @include tiny {
      width: 165%;
    }
  }
}





.content #directory-listing-container {    
    .k-pager-wrap {
        border: none;
        padding: 0;
        margin: 0;
    }
    .k-listview {
        border: none;
        padding: 0;
        margin-bottom: 10px;
    }
    .k-pager-wrap {
        //border-top: 1px solid $light-grey;
        //padding-top: 30px;
        font-size: 14px;
    }
    .alert.bg-white {
        border: none !important;
        padding: 0 !important;
        margin: 0 !important;
    }
    .k-pager-sizes {
        .k-widget {
            margin-right: 10px;
        }
    }
    ul.k-pager-numbers {
        margin: 0;
        padding: 0;
        li {
            margin: 0;
            padding: 0;
            line-height: inherit;
            .k-state-selected {
                background: $dark-grey;
                border-color: $dark-grey;
            }
            a {
                //background: $dark-grey;
                //border-color: $dark-grey;
                color: $dark-grey !important;
                font-weight: normal !important;
                line-height: 28px;
                &:hover {
                    color: $dark-grey !important;
                }
            }
            &:before {
                display: none;
            }
        }
    }
    .k-multiselect-wrap {
        ul {
            margin: 0;
            padding: 0;
            li {
                margin: 2px;
                padding: 1px 22px 1px 4px;
                &:before {
                    display: none;
                }
            }
        }
        .k-multiselect .k-button {
            border-color: $dark-grey;
            background: $dark-grey;
        }
    }
    .alert:first-child {
        margin-bottom: 40px !important;
        //background: $lightest-grey;
    }
    .fa-2x {
        font-size: 1.6em;
    }
    .btn, .input-xs {
        border-radius: 2px;
        padding: 0 10px;
    }
    
    .k-autocomplete .k-input, .k-multiselect-wrap, .k-textbox>input,
    .k-dropdown-wrap .k-input, .k-numeric-wrap .k-input, .k-picker-wrap .k-input,
    .k-autocomplete, .k-block, .k-button, .k-calendar .k-link, .k-calendar td, .k-calendar-container, .k-colorpicker, .k-combobox, .k-datepicker, .k-datetimepicker, .k-drag-clue, .k-dropdown, .k-dropdown-wrap, .k-editor-inline, .k-grid .k-filter-options, .k-grouping-header .k-group-indicator, .k-inline-block, .k-list-container, .k-multiselect, .k-notification, .k-numeric-wrap, .k-numerictextbox, .k-picker-wrap, .k-slider-selection, .k-slider-track, .k-split-button, .k-textbox, .k-tile, .k-timepicker, .k-tooltip, .k-touch-scrollbar, .k-treeview .k-in, .k-upload, .k-window, .k-window-action, .k-window-titleless .k-window-content {
        @include border-radius(0);
        box-shadow: none !important;
    }
}

.row.filters {
    padding-left: 100px;
    position: relative;
    font-size: 14px;
    margin-top: -50px;
    padding: 15px 0 0 100px;
    //background: $light-grey;
    position: relative;

    .sorter {
        position: absolute;
        top: 19px;
        left: 10px;
    }
    margin-left: -15px;
    margin-right: -15px;
    .column {
        padding-left: 15px;
        padding-right: 15px;
        input,
        select {
            width: 100%;
        }
        .input-group {
            display: block;
            width: 100%; 
            position: relative;
            .input-addon {
                position: absolute;
                top: 4px;
                left: 10px;
                z-index: 10;
                & + .k-widget {
                    padding-left: 30px;
                }
            }
        }
        .k-widget {
            width: 100%;
        }
    }
}

.content ul.upcomingMarkets {
    padding: 0 0 30px 0;
    margin:  0 0 0;
    @include clear;
    li {
        display: inline;
        padding: 0;
        margin: 0;
        background: $light-blue;
        float: left;
        color: #000;
        width: auto !important;
        &:before {
            display: none;
        }
        a {
            display: inline-block;
            padding: 7px 15px;
            text-transform: uppercase;
            @include filson-book;
            font-size: 13px;
            //text-shadow: 2px 2px 0 rgba(0,0,0,0.1);
            color: #000;
            background: rgba(255,255,255,0.2);
            margin-left: 1px;
            &:hover {
                background: $black;
                color: #fff;
                //box-shadow: inset 0 0 10px $yellow;
            }
        }
        &.heading {
            span {
                display: inline-block;
                padding: 7px 15px;
                text-transform: uppercase;
                @include filson-medium;
                font-size: 13px;
                //text-shadow: 2px 2px 0 rgba(0,0,0,0.1);
                color: #000;
            }
        }
        @media (max-width: 960px) {
            width: 100% !important;
            display: block;
            float: none;
            a {
                display: block;
                margin-left: 0;
                border-top: 1px solid $light-blue;
            }
        }
    }
}

.square-crop {
    width: 100%;
    padding-top: 100%; 
    max-height: none;
    box-sizing: content-box;
    position: relative;
    img {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.designer-page p br + br {

}


.k-autocomplete .k-input, .k-dropdown-wrap .k-input, .k-numeric-wrap .k-input, .k-picker-wrap .k-input, .k-selectbox .k-input, .k-textbox > input, .k-multiselect-wrap > input {
    //font-size: 16px;
    font-family: "filson-soft",sans-serif;
    font-style: normal;
    font-weight: 200;
}

.k-multiselect-wrap > input {
    //line-height: 1.65em;
    //padding: .177em 0;
}



.bread-crumbs {
    padding: 0 0 10px;
    text-align: left;
    @include clear;
    position: relative;
    top: -10px;
    ul {
        margin: 0;
        padding: 0;
        display: inline-block;
        
        li {
            margin: 0 8px 0 0;
            display: inline-block;
            padding: 0;
            float: left;
            a {
                font-weight: 300;
                font-size: 12px;
                //color: #aaa;
                text-transform: uppercase;
            }
            &:before {
                display: none;
            }
            &:after {
                content: "/";
                margin-left: 10px;
                opacity: 0.4;
            }
            &:last-child {
                margin-left: 0;
                &:after {
                    display: none;
                }
                a {
                    color: #333;
                }
            }
        }
    }
}

#instafeed {
  @include clear;
  margin-left: -10px !important;
  margin-right: -10px !important;
  margin-bottom: 40px;
  > div {
    display: block;
    float: left;
    width: 16.6666666667%;
    padding: 10px;
  }
  a {
    display: block;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    position: relative;
    //border: 10px solid $white;
    background: $black;
    .ig-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40px;
      margin-left: -20px;
      margin-top: -20px;
      z-index: 1000;
      @include ease;
      opacity: 0;
    }
    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover !important;
      display: block;
      @include ease;
      //transform: scale(1.05);
    }
    &:hover {
      span {
        //transform: scale(1.1);
        opacity: 0.8;
      }
      .ig-icon {
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }
  @include tablet-small {
    margin-left: auto;
    margin-right: auto;
    > div {
      width: 33.3333%;
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10),
      &:nth-child(11),
      &:nth-child(12) {
        display: none;
      }
    }
  }
  @include mobile-medium {
    margin-left: auto;
    margin-right: auto;
    > div {
      width: 50%;
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10),
      &:nth-child(11),
      &:nth-child(12) {
        display: none;
      }
    }
  }
}

.content p.larger {
    font-size: 144% !important;
}




.content .fkah-form .form fieldset>h2 {
  font-size: 16px;
}

.content .fkah-form .form fieldset>div label {
  font-size: 15px;
}

.fkah-form {
  max-width: 1000px;
  h4 {
    border-color: #ddd;
  }
}

.stats {
  margin-top: 30px;
  @include clear;
  background: #d79b93;
  h4 {
    margin: 0;
    border-color: rgba(255,255,255,0.3);
    padding: 20px;
    color: $white;
    text-align: center;
    @include filson-medium;
    font-size: 14px;
    letter-spacing: 1px;
  }
  > div {
    width: 33.33333%;
    float: left;
    padding: 20px;
    border-left: 1px solid rgba(255,255,255,0.3);
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $white;
    text-align: center;
    strong {
      font-size: 44px;
      display: block;
      line-height: 1;
      letter-spacing: 0;
      padding-bottom: 5px;
      //color: $blue;
      font-family: "filson-pro",sans-serif;
      font-weight: 900;
    }
    &:first-of-type {
      border: none;
    }
    @include tablet {
      width: 100%;
      float: none;
      border-left: none;
      border-top: 1px solid rgba(255,255,255,0.3);
    }
  }
}







// APPLICATION PAGE STYLES

$green: #5a8f78;
$grey-blue: #566a82;
$gold: #b9935b;
$lightest-grey: #f4f4f4;
.content {
  .alert-block-yellow {
    margin-bottom: 30px;
    @include filson-medium;
    font-size: 16px;
    background: $yellow;
    line-height: 1.6;
    padding: 15px 20px;
  }
  .container.application-form {
    max-width: 900px;
    padding: 50px 60px;
    font-size: 15px;
    color: #1d1d1b;
    > h3 {
      margin-top: 40px;
      padding-bottom: 0;
      margin-bottom: 20px;
      border: none;
      font-size: 20px;
      color: #1d1d1b;
      &.green {
        color: $green;
      }
      &.blue {
        color: $grey-blue;
      }
      &.gold {
        color: $gold;
      }
    }
    > h4 {
      margin: 30px 0 20px;
      letter-spacing: 1px;
      font-size: 15px;
    }
    > p {
      margin-bottom: 20px;
    }
    .screen-shots {
      img {
        box-shadow: 0 0 6px rgba(0,0,0,0.2);
        max-width: 600px;
        border-radius: 10px;
        overflow: hidden;
      }
      p {
        margin-bottom: 40px;
        text-align: center;
        @include filson-medium;
        font-size: 12px;
        letter-spacing: 1px;
        &:last-child {
          margin: 0;
        }
      }
    }
    .disclaimer {
      padding: 20px;
      //text-align: center;
      background: $lightest-grey;
      border-radius: 10px;
      margin: 50px auto;
      //max-width: 600px;
      h3 {
        font-size: 16px;
        margin: 0 0 15px;
      }
      p {
        font-style: italic;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .form-row {
      padding: 20px;
      border-top: 1px solid rgba(0,0,0,.05)!important;
      font-size: 15px;
      line-height: 1.6;
      @include filson-book;
      &.icon {
        position: relative;
        padding-left: 36px !important;
        background: $white !important;
        i,
        svg {
          position: absolute;
          top: 17px;
          left: 0;
          font-size: 24px;
          color: $gold;
        }
      }
    }
    .form {
      margin-bottom: 40px;
      h3.form-group-heading {
        display: block;
        padding: 16px 20px;
        margin: 0;
        font-size: 14px;
        text-transform: uppercase;
        font-family: filson-pro,sans-serif;
        font-style: normal;
        font-weight: 300;
        letter-spacing: 1px;
        background: #f2f2f2;
        color: #1d1d1b;
        line-height: 1.3;
      }
      .form-group.blue {
        .form-row.icon {
          i,
          svg {
            color: $grey-blue;
          }
        }
      }
      .form-group.green {
        .form-row.icon {
          i,
          svg {
            color: $green;
          }
        }
      }
    }
  }
}